import React, { createRef, Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { library } from '@fortawesome/fontawesome-svg-core'

import Header from "~components/global/header"
import Footer from "~components/global/footer"
import "@/styles/layout.css"

import { 
  faPhoneAlt, 
  faCheck,
  faHammer,
  faRuler,
  faDraftingCompass,
  faCarBattery
} from '@fortawesome/pro-solid-svg-icons'

import { 
  faAngleRight,
  faPaperPlane
} from '@fortawesome/pro-light-svg-icons'

import { faWhatsapp, faInstagram, faFacebookSquare  } from "@fortawesome/free-brands-svg-icons"

library.add(
  faPhoneAlt,
  faAngleRight,
  faCheck,
  faHammer,
  faRuler,
  faDraftingCompass,
  faCarBattery,
  faWhatsapp,
  faPaperPlane,
  faInstagram,
  faFacebookSquare
)

export default class Layout extends Component {
  constructor () {
    super()

    this._handleScroll = this._handleScroll.bind(this)
    this.backgroundContainer = createRef()
  }

  _handleScroll (e) {
    this.backgroundContainer.current.style.backgroundPositionY = `${window.scrollY * -0.125}px`
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this._handleScroll)
      this._handleScroll()
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this._handleScroll)
  }

  render () {
    const { children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="main">
              <Header siteTitle={data.site.siteMetadata.title} />
              <main>
                {children}
              </main>
              <Footer />
            </div>
            <div className="bg" ref={this.backgroundContainer}></div>
          </>
        )}
      />
    )
  }
}
