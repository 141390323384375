import React from "react"
import { StaticQuery, graphql } from "gatsby"
import AniLink from "~components/common/anilink"
import Container from "~components/common/container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FooterLogo from "@/images/footer-logo.inline.svg"
import DasHandwerkLogo from "@/images/dashandwerk-logo.inline.svg"

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          info: dataJson {
            company_name
            city
            email
            post_code
            phone {
              human
              machine
            }
            address
            directions_link
            instagram_link
            facebook_link
          }
        }
      `}
      render={data => (
        <footer>
          <Container className="relative text-brown-light py-xl lg:flex">
            <div className="gutter mt-sm mb-lg lg:w-1/6">
              <FooterLogo />
            </div>
            <div className="gutter my-sm lg:w-1/4">
              <address>
                {data.info.address}<br />
                {data.info.city}
              </address>

              {data.info.directions_link &&
              <a href={data.info.directions_link} target="_blank" rel="noopener noreferrer" className="text-brown-lighter hover:text-white">
                Route planen
                <FontAwesomeIcon icon={['fal', 'angle-right']} fixedWidth />
              </a>
              }

            </div>
            <ul className="gutter my-sm lg:w-1/3">
              <li>
                <a href={ `tel:${data.info.phone.machine}` } className="hover:text-white">
                  { data.info.phone.human }
                </a>
              </li>
              <li>
                <a href={ `mailto:${data.info.email}` } className="hover:text-white">
                  { data.info.email }
                </a>
              </li>
              <li className="mt-1">
                <a href={data.info.instagram_link} target="_blank" rel="noopener noreferrer"
                  className="hover:text-white">
                  <FontAwesomeIcon icon={["fab", "instagram"]} fixedWidth  className="mr-xs fa-lg" />
                </a>
                <a href={data.info.facebook_link} target="_blank" rel="noopener noreferrer"
                 className="hover:text-white">
                  <FontAwesomeIcon icon={["fab", "facebook-square"]} fixedWidth className="mr-xs fa-lg"/>
                </a>
              </li>
            </ul>
            <nav className="gutter my-sm lg:w-1/4">
              <ul>
                <li>
                  <AniLink to="/datenschutz" className="hover:text-white">Datenschutz</AniLink>
                </li>
                <li>
                  <AniLink to="/impressum" className="hover:text-white">Impressum</AniLink>
                </li>
              </ul>
            </nav>

            <div className="absolute right-0 bottom-0 pr-gutter mr-gutter">
              <a href="https://handwerk.de" target="_blank" rel="noopener noreferrer" title="Das Handwerk Website">
                <DasHandwerkLogo />
              </a>
            </div>
          </Container>
        </footer>
      )}
    />
  )
}

export default Footer
