import PropTypes from "prop-types"
import React from "react"

const Container = ({ className, standalone, children }) => (
  <div className={[`container ${standalone ? 'gutter-wide' : 'gutter'}`, className].join(' ')}>
    {children}
  </div>
)

Container.propTypes = {
  className: PropTypes.string,
  standalone: PropTypes.bool
}

Container.defaultProps = {
  className: '',
  standalone: false
}

export default Container
