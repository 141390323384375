import PropTypes from "prop-types"
import React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ to, onClick, children, ...props }) => (
  <GatsbyLink to={to} onClick={onClick} {...props}>
    {children}
  </GatsbyLink>
)

Link.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
}
  
export default Link
  