import React, { useContext } from "react"
import AniLink from "~components/common/anilink"
import { GlobalDispatchContext } from "~components/global/context-provider"

export default ({ to, children, ...props }) => {
  const dispatch = useContext(GlobalDispatchContext)

  return (
    <li className="md:mx-sm" {...props}>
      <AniLink to={to} activeStyle={{ color: "white" }} partiallyActive={true} onClick={() => dispatch({ type: "HIDE_MENU" })} className="hover:text-white">
        {children}
      </AniLink>
    </li>
  )
}
