import AniLink from "~components/common/anilink"
import PropTypes from "prop-types"
import React from "react"
import Logo from "@/images/logo.inline.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import info from "@/data/info"
import Navigation from "~components/global/navigation"
import Container from "~components/common/container"

const Header = ({ siteTitle }) => (
  <header className="font-display mb-xl text-sm relative top-0 inset-x-0 z-infinite lg:max-w-screen-xl lg:mx-auto">
    <Container standalone className="py-sm text-right">
      <a href={ `tel:${info.phone.machine}` } className="text-white py-2">
        <FontAwesomeIcon icon="phone-alt" fixedWidth className="mr-xs w-5" />
        { info.phone.human }
      </a>
    </Container>
    <Container standalone className="flex items-center justify-between">
      <AniLink to="/">
        <Logo alt={siteTitle} className="logo" />
      </AniLink>
      <Navigation />
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
