import React, { useContext } from "react"
import NavigationItem from "~components/global/navigation-item"
import { GlobalDispatchContext, GlobalStateContext } from "~components/global/context-provider"

export default () => {
  const dispatch = useContext(GlobalDispatchContext)
  const { menuOpen } = useContext(GlobalStateContext)
  
  return (
    <>
      <button className={`hamburger relative z-50 lg:hidden ${menuOpen ? 'active' : ''}`} type="button" onClick={() => dispatch({ type: "TOGGLE_MENU" })}>
        <span className="top"></span>
        <span className="middle"></span>
        <span className="bottom"></span>
      </button>
      <nav className={`bg-black font-display text-brown-light text-xl text-center leading-loose flex items-center justify-center fixed z-40 inset-0 transform transition duration-300 ease-in-out lg:static lg:translate-x-0 lg:transition-none lg:opacity-100 lg:text-sm lg:bg-transparent lg:uppercase ${menuOpen ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full'}`}>
        <ul className="lg:flex">
          <NavigationItem to="/" className="lg:hidden">Startseite</NavigationItem>
          <NavigationItem to="/unternehmen">Unternehmen</NavigationItem>
          <NavigationItem to="/referenzen">Referenzen</NavigationItem>
          <NavigationItem to="/werkbankplatten">Werkbankplatten</NavigationItem>
          <NavigationItem to="/karriere">Karriere</NavigationItem>
          <NavigationItem to="/kontakt">Kontakt</NavigationItem>
        </ul>
      </nav>
    </>
  )
}
